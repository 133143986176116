import * as React from 'react';
import {
  PDFDownloadLink, Page, View, StyleSheet, Document, Text, Image,
} from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import tower10050 from './presets/tower10050.json';
import a4 from './presets/a4.json';
import towerW100 from './presets/towerw100.json';
import {read, utils, writeFile} from 'xlsx-js-style';
import {
  Accordion, AccordionActions, AccordionDetails, AccordionSummary, AppBar,
  Box, Button, ButtonGroup, Card, CardContent,
  Container, IconButton, InputAdornment, MenuItem, TextField,
  Toolbar, Typography,
} from '@mui/material';
import {Add, ExpandMore, RemoveCircle} from '@mui/icons-material';

const MM_TO_PT = 2.83465;

const acceptFormats = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
].join(',');

const currencies = {
  'ZAR': 'R',
  'USD': '$',
  'EUR': '€',
  'GBP': '£',
  'NONE': null,
};

/**
 * Converts millimeters to pt string for stylesheets
 * @param {number} mm
 * @return {string}
 */
// eslint-disable-next-line no-unused-vars,require-jsdoc
function mmToPt(mm) {
  return (mm * MM_TO_PT) + 'pt';
}

/**
 * @return{JSX.Element}
 * @constructor
 */
export default class App extends React.Component {
  /**
   * @param {any} props
   */
  constructor(props) {
    super(props);
    const params = new URLSearchParams(document.location.search);
    const fieldHiddenMask = params.get('fhm') || 0;

    this.itemFieldsHidden = {
      vendor: (fieldHiddenMask & 1) === 1,
      productTitle: (fieldHiddenMask & 2) === 2,
      codeQuantity: (fieldHiddenMask & 4) === 4,
      price: (fieldHiddenMask & 8) === 8,
      stockCode: (fieldHiddenMask & 16) === 16,
    };

    const fieldDisabledMask = params.get('fdm') || 0;

    this.itemFieldsDisabled = {
      vendor: (fieldDisabledMask & 1) === 1,
      productTitle: (fieldDisabledMask & 2) === 2,
      codeQuantity: (fieldDisabledMask & 4) === 4,
      price: (fieldDisabledMask & 8) === 8,
      stockCode: (fieldDisabledMask & 16) === 16,
    };


    this.state = {
      ...a4,
      items: [
        {
          vendor_id: params.get('vid') || '',
          product_title: params.get('pt') || '',
          price: params.get('price') || '',
          sku: params.get('sku') || '',
          quantity: 1,
        },
      ],
      doc: null,
      preset: 'a4',
      qr_margin: 5,
      excel: false,
      currency: 'ZAR',
      itemExtraProps: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleItemInputChange = this.handleItemInputChange.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.generateExampleExcel = this.generateExampleExcel.bind(this);
    this.importExcel = this.importExcel.bind(this);
    this.loadPreset = this.loadPreset.bind(this);
    this.setError = this.setError.bind(this);
  }

  /**
   * @param {any} event
   */
  handleInputChange(event) {
    console.log(event.target);
    if (event.target.dataset['state']) {
      this.setState({[event.target.dataset['state']]: event.target.value});
    }

    const nopreset =
      event.target.dataset['nopreset'] &&
      event.target.dataset['nopreset'] === 'true';
    const presetState = !nopreset ? {preset: null} : {};

    this.setState({doc: null, ...presetState});
  }

  /**
   * @param {number} index
   * @param {boolean} enabled
   * @param {string} key
   * @param {string} msg
   */
  setError(index, enabled, key, msg) {
    const props = this.state.itemExtraProps;
    props[index] = props[index] || {};
    props[index][key] = {
      ...props[index][key] || {},
      error: enabled,
      helperText: msg,
    };
    this.setState({itemExtraProps: props});
  }

  /**
   * @param {any} event
   */
  handleItemInputChange(event) {
    const el = event.currentTarget;
    if (
      el.dataset['index'] &&
      el.dataset['state']
    ) {
      const i = parseInt(el.dataset['index']);
      const s = el.dataset['state'];
      const items = this.state.items;

      if (el.type === 'number') {
        let number = parseFloat(el.value);
        if (!isNaN(number) || el.value === '') {
          this.setError(i, false, s, '');
        } else {
          number = null;
          this.setError(i, true, s, 'Please enter a valid number');
        }

        if (
          number != null &&
          number > 0
        ) {
          items[i][s] = number;
        } else {
          if (Object.keys(el.dataset).includes('nullable')) {
            items[i][s] = el.dataset['nullable'];
          } else {
            items[i][s] = 0;
          }
        }
      } else {
        items[i][s] = el.value;
      }
      this.setState({items});
    }

    this.setState({doc: null});
  }

  /**
   * @param {any} event
   * @param {any} payload
   */
  addItem(event, payload) {
    const {item, index} = payload || {};
    const items = this.state.items;
    if (!item) {
      const params = new URLSearchParams(document.location.search);
      items.push({
        vendor_id: params.get('vid') || '',
        product_title: params.get('pt') || '',
        price: params.get('price') || '',
        sku: params.get('sku') || '',
        quantity: 1,
      });
    } else {
      if (index !== undefined) {
        items[index] = item;
      } else {
        items.push(item);
      }
    }
    this.setState({items, doc: null});
  }

  /**
   * @param {any} event
   */
  removeItem(event) {
    event.stopPropagation();
    const items = this.state.items;
    if (event.currentTarget.dataset && event.currentTarget.dataset['index']) {
      items.splice(event.currentTarget.dataset['index'], 1);
      this.setState({items, doc: null});
    }
  }

  /**
   * @param {any} event
   */
  generatePDF(event) {
    const styles = StyleSheet.create({
      page: {
        paddingLeft: mmToPt(this.state.margin_left),
        paddingRight: mmToPt(this.state.margin_right),
        paddingTop: mmToPt(this.state.margin_top),
        paddingBottom: mmToPt(this.state.margin_bottom),
      },
      pageInner: {
        flexDirection: 'row',
        display: 'flex',
        width: '100%',
      },
      column: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
      },
      row: {
        // borderStyle: 'solid',
        // borderColor: 'black',
        borderWidth: 0,
        width: mmToPt(this.state.label_width),
        height: mmToPt(this.state.label_height),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      qr: {
        // width: mmToPt(
        //    Math.min(this.state.label_width, this.state.label_height) - 10),
        // height: mmToPt(
        //    Math.min(this.state.label_width, this.state.label_height) - 10),
        margin: mmToPt(this.state.qr_margin || 2),
      },
      textContent: {
        display: 'flex',
        flexDirection: 'column',
      },
      title: {
        fontSize: '10pt',
      },
      meta: {
        fontSize: '15pt',
      },
    });

    const codesPerPage = this.state.columns * this.state.rows;
    let totalQuantity = 0;
    this.state.items.forEach((i) => totalQuantity += parseInt(i.quantity));
    const totalPages = Math.ceil(totalQuantity / codesPerPage);
    let curItem = 0;
    const generated = [0];

    const pages = [];
    for (let p = 0; p < totalPages; p++) {
      const columns = [];
      for (let c = 0; c<this.state.columns; c++) {
        const rows = [];
        for (let r = 0; r<this.state.rows; r++) {
          let rowItem =
            <View key={r} style={styles.row}></View>;

          if (
            generated[curItem] === parseInt(
                this.state.items[curItem].quantity) &&
            curItem < this.state.items.length-1
          ) {
            curItem ++;
            generated[curItem] = 0;
          }
          if (generated[curItem] < parseInt(
              this.state.items[curItem].quantity)) {
            const qrCode = document.getElementById(`${curItem}-qr`);
            const item = this.state.items[curItem];
            rowItem = <View key={r} style={styles.row}>
              <View style={{width: '100%'}}>
                <Image
                  src={qrCode.toDataURL()}
                  style={styles.qr}
                />
              </View>
              <View style={{...styles.textContent, width: '100%', padding: 5}}>
                <Text style={styles.title} wrap={false}>
                  {item.product_title}
                </Text>
                <Text style={styles.title} wrap={false}>
                  {item.sku}
                </Text>
                <View style={{flexGrow: 1}}></View>
                <Text style={styles.meta}>
                  {item.price ?
                    `${currencies[this.state.currency] || ''}${item.price}` :
                    ''}
                </Text>
              </View>
            </View>;

            generated[curItem] ++;
          }
          rows.push(rowItem);
        }
        columns.push(
            <View key={c} style={styles.column}>
              {rows}
            </View>,
        );
      }

      pages.push(<Page
        key={p}
        style={styles.page}
        size={{
          width: this.state.width * MM_TO_PT,
          height: this.state.height * MM_TO_PT,
        }}
      >
        <View style={styles.pageInner}>
          {columns}
        </View>
      </Page>);
    }

    const doc = <Document>
      {pages}
    </Document>;
    this.setState({doc});
  }

  /**
   * @param {any} event
   */
  generateExampleExcel(event) {
    const fitToColumn = (aoa) => {
      return aoa[0].map(
          (a, i) => (
            {
              wch: Math.max(...aoa.map(
                  (a2) => a2[i].v ? a2[i].v.toString().length : 0),
              ) + 10,
            }
          ));
    };

    const prefill = [];
    if (this.state.items[0].vendor_id !== '') {
      prefill.push(this.state.items[0].vendor_id);
    }

    const s = {
      font: {
        bold: true,
      },
      fill: {
        fgColor: {
          rgb: '00AAAA',
        },
      },
    };
    const t = 's';
    const headings = [
      [
        !this.itemFieldsHidden.vendor ? {s, t, v: 'Vendor ID'} : {v: ''},
        !this.itemFieldsHidden.productTitle ? {s, t, v: 'Code Title'} : {v: ''},
        !this.itemFieldsHidden.quantity ? {s, t, v: 'Quantity'} : {v: ''},
        !this.itemFieldsHidden.price ?
          {s: {...s, numFmt: 'R0.00'}, t, v: 'Price*'} :
          {v: ''},
        !this.itemFieldsHidden.stockCode ? {s, t, v: 'Stock Code*'} : {v: ''},
      ],
    ];

    const help = [
      [
        {
          v: 'Instructions:',
          t: 's',
          s: {
            font: {
              bold: true,
            },
          },
        },
      ],
      [
        'Please modify columns A to E and ' +
        'add all the items you wish to generate labels for.',
      ],
      [
        'Each row will be a set of labels for one item.',
      ],
      [
        'All columns marked with and asterisk (*) are optional,' +
        ' if you do not know what to fill in, leave it blank.',
      ],
      [
        'Anything that is not in columns A to E will not be read,' +
        ' so if you would like to add formulas for yourself you can do',
      ],
      [
        'it from column F and onwards.',
      ],
      [
        'If the Vendor ID is already filled in cell A2, just copy it to every' +
        ' row that you create',
      ],
      [
        'Only this sheet will be processed, DO NOT RENAME IT',
      ],
      [
        'DO NOT change any of the column titles in columns A to E',
      ],
      [
        'If you choose to fill out the price field, DO NOT include a' +
        ' currency symbol, for example, type "100" instead of "R100".',
      ],
    ];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings, {origin: 'A1'});
    utils.sheet_add_aoa(ws, [prefill], {origin: 'A2'});
    utils.sheet_add_aoa(ws, help, {origin: 'G1'} );
    ws['!cols'] = fitToColumn(headings);
    utils.book_append_sheet(wb, ws, 'Items');
    writeFile(wb, 'QR_Code_Label_Example.xlsx');
  }

  /**
   * @param {any} event
   */
  importExcel(event) {
    const columns = {
      0: 'vendor_id',
      1: 'product_title',
      2: 'quantity',
      3: 'price',
      4: 'sku',
    };

    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, {});
        const sheets = wb.Sheets;

        const sheet = sheets['Items'];
        if (sheet) {
          const {r} = utils.decode_range(sheet['!ref']).e;
          let rowCount = 0;
          for (let row = 1; row <= r; row ++) {
            let item = {};
            for (let column = 0; column <= 4; column ++) {
              const cell = utils.encode_cell({c: column, r: row});
              if (sheet[cell]) {
                let {v} = sheet[cell];
                if (!item) break;
                switch (column) {
                  case 2:
                    try {
                      v = parseInt(v);
                      if (v < 1) item = null;
                      else item[columns[column]] = v;
                    } catch (e) {
                      item = null;
                    }
                    break;

                  case 3:
                    try {
                      v = parseInt(v);
                      item[columns[column]] = `${v}`;
                    } catch (e) {
                      item[columns[column]] = '';
                    }
                    break;

                  default:
                    item[columns[column]] = v;
                    break;
                }
              } else {
                if (column < 2) {
                  item = null;
                  break;
                }
                if (column === 2) item['quantity'] = 1;
                if (column > 2) item[columns[column]] = '';
              }
            }
            if (item) {
              this.addItem(null, {item, index: rowCount});
              rowCount ++;
            }
          }
          this.setState({excel: true});
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  /**
   * @param {any} event
   */
  loadPreset(event) {
    this.setState({doc: null});
    if (event.target.dataset && event.target.dataset.preset) {
      switch (event.target.dataset.preset) {
        case 'tower10050':
          this.setState({...tower10050, preset: event.target.dataset.preset});
          break;
        case 'towerw100':
          this.setState({...towerW100, preset: event.target.dataset.preset});
          break;
        case 'a4':
          this.setState({...a4, preset: event.target.dataset.preset});
          break;
      }
    }
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const items = this.state.items.map((v, i) => {
      return <Card key={i} style={{margin: 10}}>
        <CardContent>
          <Box display='flex'>
            {
              i !== 0 ?
                <Box
                  display='flex'
                  width='60px'
                  maxWidth='60px'
                  alignItems='center'
                >
                  <IconButton
                    style={{margin: 'auto'}}
                    color='error'
                    data-index={i}
                    onClick={this.removeItem}
                  >
                    <RemoveCircle />
                  </IconButton>
                </Box> :
                <Box width='60px'></Box>
            }
            <Box display='flex' style={{flexGrow: 1}}>
              <Container style={{flexGrow: 1}}>
                <TextField
                  label='Vendor Id'
                  style={
                    {
                      margin: 5,
                      display:
                        this.itemFieldsHidden.vendor || false ?
                          'none' : '',
                    }
                  }
                  type="text"
                  variant='standard'
                  value={v.vendor_id}
                  onChange={this.handleItemInputChange}
                  disabled={this.itemFieldsDisabled.vendor || false}
                  inputProps={
                    {
                      'data-state': 'vendor_id',
                      'data-index': i,
                    }
                  }
                />
                <TextField
                  label='Item Title'
                  style={
                    {
                      margin: 5,
                      display:
                        this.itemFieldsHidden.productTitle || false ?
                          'none' : '',
                    }
                  }
                  type="text"
                  variant='standard'
                  value={v.product_title}
                  onChange={this.handleItemInputChange}
                  disabled={this.itemFieldsDisabled.productTitle || false}
                  inputProps={
                    {
                      'data-state': 'product_title',
                      'data-index': i,
                    }
                  }
                />
                <TextField
                  label='Quantity'
                  style={
                    {
                      margin: 5,
                      display:
                        this.itemFieldsHidden.codeQuantity || false ?
                          'none' : '',
                    }
                  }
                  type="number"
                  variant='standard'
                  value={v.quantity}
                  onChange={this.handleItemInputChange}
                  disabled={this.itemFieldsDisabled.codeQuantity || false}
                  inputProps={
                    {
                      'data-state': 'quantity',
                      'data-index': i,
                    }
                  }
                />
                <TextField
                  label='Item Price'
                  {...(
                      this.state.itemExtraProps[i] &&
                      this.state.itemExtraProps[i]['price'] ?
                        this.state.itemExtraProps[i]['price'] : {}
                  )
                  }
                  style={
                    {
                      margin: 5,
                      display:
                        this.itemFieldsHidden.price || false ?
                          'none' : '',
                    }
                  }
                  type="number"
                  variant='standard'
                  value={v.price}
                  onChange={this.handleItemInputChange}
                  disabled={this.itemFieldsDisabled.price || false}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        {currencies[this.state.currency] || ''}
                      </InputAdornment>,
                  }}
                  inputProps={
                    {
                      'data-state': 'price',
                      'data-index': i,
                      'data-nullable': '',
                    }
                  }
                />
                <TextField
                  label='Stock Code'
                  style={
                    {
                      margin: 5,
                      display:
                        this.itemFieldsHidden.stockCode || false ?
                          'none' : '',
                    }
                  }
                  type="text"
                  variant='standard'
                  value={v.sku}
                  onChange={this.handleItemInputChange}
                  disabled={this.itemFieldsDisabled.stockCode || false}
                  inputProps={
                    {
                      'data-state': 'sku',
                      'data-index': i,
                    }
                  }
                />
              </Container>
              <Box
                height='100%'
                display='flex'
              >
                <QRCode
                  style={
                    {
                      margin: 'auto',
                      padding: '10px',
                      backgroundColor: 'white',
                      borderRadius: '10px',
                    }
                  }
                  id={i + '-qr'}
                  className='qr-code'
                  value={
                    [
                      v.vendor_id,
                      v.product_title,
                      v.price || '',
                      v.sku || '',
                    ].join('|')
                  }
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>;
    });

    return <Container>
      <AppBar
        position='static'
      >
        <Toolbar>
          <Box display='flex' width='100%'>
            <Typography variant='h4'>
              QR Label Generator
            </Typography>
            <Box style={{flexGrow: 1}}></Box>
            <TextField
              id='currency-select'
              variant='standard'
              label='Currency'
              value={this.state.currency}
              onChange={
                (_, v) => {
                  this.setState({currency: v.props.value});
                }
              }
              select
            >
              {
                Object.keys(currencies).map(
                    (key) => (
                      <MenuItem key={key} value={key}>
                        {key} {currencies[key] ? `(${currencies[key]})` : ''}
                      </MenuItem>
                    ),
                )
              }
            </TextField>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{my: 4}}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography sx={{width: '33%', flexShrink: 0}}>
              Presets
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
              Here you can select preset label layouts
            </Typography>
          </AccordionSummary>
          <AccordionActions>
            <ButtonGroup>
              <Button
                variant='contained'
                onClick={this.loadPreset}
                data-preset="a4"
                color={this.state.preset === 'a4' ? 'secondary' : 'primary'}
              >
                {a4.title}
              </Button>
              <Button
                variant='contained'
                onClick={this.loadPreset}
                data-preset="towerw100"
                color={this.state.preset === 'towerw100' ?
                          'secondary' : 'primary'}
              >
                {towerW100.title}
              </Button>
              <Button
                variant='contained'
                onClick={this.loadPreset}
                data-preset="tower10050"
                color={this.state.preset === 'tower10050' ?
                          'secondary' : 'primary'}
              >
                {tower10050.title}
              </Button>
            </ButtonGroup>
          </AccordionActions>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography sx={{width: '33%', flexShrink: 0}}>
              Page Properties
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
              Customise the page size and label layout
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container>
              <TextField
                style={{margin: 5}}
                label='Width (cm)'
                variant='standard'
                type="number"
                value={this.state.width}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'width'}}
              />
              <TextField
                style={{margin: 5}}
                label='Height (cm)'
                data-state='height'
                variant='standard'
                type="number"
                value={this.state.height}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'height'}}
              />
            </Container>
            <Container>
              <TextField
                style={{margin: 5}}
                label='Rows'
                data-state='rows'
                variant='standard'
                type="number"
                value={this.state.rows}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'rows'}}
              />
              <TextField
                style={{margin: 5}}
                label='Columns'
                data-state='columns'
                variant='standard'
                type='number'
                value={this.state.columns}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'columns'}}
              />
            </Container>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography sx={{width: '33%', flexShrink: 0}}>
              Label Properties
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
              Customise the label size and margins
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container>
              <TextField
                style={{margin: 5}}
                label='Width (cm)'
                variant='standard'
                type="number"
                value={this.state.label_width}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'label_width'}}
              />
              <TextField
                style={{margin: 5}}
                label='Height (cm)'
                variant='standard'
                type="number"
                value={this.state.label_height}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'label_height'}}
              />
            </Container>
            <Container>
              <TextField
                style={{margin: 5}}
                label='Left Margin (cm)'
                variant='standard'
                type="number"
                value={this.state.margin_left}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'margin_left'}}
              />
              <TextField
                style={{margin: 5}}
                label='Right Margin (cm)'
                variant='standard'
                type="number"
                value={this.state.margin_right}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'margin_right'}}
              />
            </Container>
            <Container>
              <TextField
                style={{margin: 5}}
                label='Top Margin (cm)'
                variant='standard'
                type="number"
                value={this.state.margin_top}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'margin_top'}}
              />
              <TextField
                style={{margin: 5}}
                label='Bottom Margin (cm)'
                variant='standard'
                type="number"
                value={this.state.margin_bottom}
                onChange={this.handleInputChange}
                inputProps={{'data-state': 'margin_bottom'}}
              />
            </Container>
            <Container>
              <label>
                <TextField
                  style={{margin: 5}}
                  label='QR Code Margin (cm)'
                  helperText='Increase if QR codes are cut of by your printer'
                  variant='standard'
                  type="number"
                  value={this.state.qr_margin || 2}
                  onChange={this.handleInputChange}
                  inputProps={
                    {
                      'data-state': 'qr_margin',
                      'data-nopreset': true,
                    }
                  }
                />
              </label>
            </Container>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography sx={{width: '33%', flexShrink: 0}}>
              Import Items
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
              Import your items from an excel spreadsheet
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can import excel files that contain the information
              for the labels you wish to generate, please download the
              example file and edit it accordingly to make sure we can
              generate the correct labels for you. After that you
              can upload the file again to generate the labels.
            </Typography>
          </AccordionDetails>
          <AccordionActions>
            <Button
              variant='contained'
              component="label"
            >
              Import Excel File
              <input
                type='file'
                accept={acceptFormats}
                onChange={this.importExcel}
                hidden
              />
            </Button>
            <Button onClick={this.generateExampleExcel}>
              Download Example file
            </Button>
          </AccordionActions>
        </Accordion>
        <Container>
          {items}
          <Button onClick={this.addItem}><Add /> Add another item</Button>
        </Container>
        <Box
          textAlign='center'
          margin='10px'
        >
          {
            this.state.excel ?
              <Typography color='orange'>
                You have imported data from an excel file, please double check
                that everything is correct before generating the PDF.
              </Typography> :
              <Box></Box>
          }
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          {
            this.state.doc ?
              <div>
                <Button
                  onClick={() => this.setState({doc: null})}
                >
                  Reset
                </Button>
                <PDFDownloadLink
                  document={this.state.doc}
                  fileName={`
                ${this.state.items[0].vendor_id}-
                ${this.state.items[0].product_title
                      .replace(/[^a-z0-9]/gi, '_')
                      .toLowerCase()
                  }.pdf`}
                >
                  <Button
                    id="download"
                    variant='contained'
                    color='secondary'
                  >
                    Download PDF
                  </Button>
                </PDFDownloadLink>
              </div> :
              <Button
                id="generate"
                variant='contained'
                onClick={this.generatePDF}
              >
                Generate PDF
              </Button>
          }
        </Box>
      </Box>
    </Container>;
  }
};
