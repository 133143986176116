import {red} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#002000',
      paper: '#002500',
    },
    primary: {
      main: '#00aa00',
    },
    secondary: {
      main: '#0033aa',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
